<template>
  <div class="promotionRecord">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">分享记录</div>
      <div class="rightBtn"></div>
    </div>
    <div class="totalNum">已经推广: {{ total }}人</div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="userItem" v-for="item in list" :key="item.id">
          <div class="avatar">
            <ImgDecypt
              class="avatarImg"
              :src="item.portrait"
              :key="item.portrait"
              round
            />
          </div>
          <div class="userName">{{ item.name }}</div>
          <div class="registerDate">
            注册时间: {{ handleDate(item.createAt) }}
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { queryPromotionRecord } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import PullRefresh from "@/components/PullRefresh";
export default {
  components: {
    ImgDecypt,
    PullRefresh,
  },
  created() {
    this.getList();
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      total: 0,
    };
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
        // uid: 224411,
      };
      try {
        let res = await this.$Api(queryPromotionRecord, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          this.total = res.data.total;
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === "refresh") {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      console.log(123123);
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList("refresh");
    },
    handleDate(date) {
      if (!date) return "";
      let newDate = new Date(date);
      let y = newDate.getFullYear();
      let m =
        newDate.getMonth() + 1 < 10
          ? "0" + (newDate.getMonth() + 1)
          : newDate.getMonth() + 1;
      let d = newDate.getDay() < 10 ? "0" + newDate.getDay() : newDate.getDay();
      return `${y}.${m}.${d}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.promotionRecord {
  height: 100%;
  color: #000;
  // background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-bottom: 0.5px solid #F2F2F2;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .totalNum {
    font-size: 12px;
    margin: 12px 0 0;
    text-align: center;
    color: #333;
  }

  .main {
    height: calc(100% - 85px);
    overflow-y: auto;
    padding: 0 12px;
    box-sizing: border-box;

    .userItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      padding: 6px 12px;
      box-sizing: border-box;
      background: #fff;
      border-radius: 8px;

      .avatar {
        width: 38px;
        height: 38px;

        /deep/ .vanImage {
          background: #00000000 !important;
        }

        /deep/ .van-image__error {
          background: #00000000 !important;
        }
      }
      .userName {
        font-size: 16px;
        font-weight: 500;
        flex: 2;
        margin: 0 12px;
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .registerDate {
        font-size: 12px;
        color: #333;
      }
      //   .infoBox {
      //   }
    }
  }
}
</style>
